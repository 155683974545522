import React, { useEffect } from "react";
import { Link } from "react-scroll";

const NavMenu = ({ show, onClose }) => {
    return (
        show ?
            <div className="nav">
                <div className="nav__logo"onClick={onClose} >
                    <img loading="lazy" src={require("../../assets/logo.png").default} alt="logo" />
                </div>

                <Link to="header" className="nav__option" onClick={onClose}>Home</Link>
                <Link to="about" className="nav__option" onClick={onClose}>About us</Link>
                <Link to="investment" className="nav__option" onClick={onClose}>Investment Focus</Link>
                <Link to="philosophy" className="nav__option" onClick={onClose}>Our Philosophy</Link>

                <div className="w-fit m-auto rounded-pill bg-white my-5 py-2 px-4">Stake Now</div>

                <div className="nav__close material-icons-outlined" onClick={onClose}>
                    close
                </div>
            </div>
            :
            <></>
    );
}

export default NavMenu;