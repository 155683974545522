import React from "react";

const Loader = () => {
  return (
    <div className="loading__page d-flex flex-column justify-content-center">
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="loader">
            <svg viewBox="0 0 80 80">
                <rect x="8" y="8" width="64" height="64"></rect>
            </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;