import React, { useEffect, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import { Header, Footer,Loader, Cookies } from "../common";
import { Element } from "react-scroll";

const Home = lazy(() => delayForClient(import('./Home')));
// const About = lazy(() => delayForClient(import("./About")));
// const Contact = lazy(() => delayForClient(import("./Contact")));

function delayForClient(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 2500);
    }).then(() => promise);
} 

const Application = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Element name="header" className="p-5">
            </Element>

            <Header />

            <img className="background" src={require("../../assets/background.png").default} alt="Background. Parachain." />

            {/* <Cookies /> */}

            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>

            <Footer />
        </Suspense>
    );
};

export default Application;