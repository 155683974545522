import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer>
            <div className="d-flex flex-column justify-content-start align-items-start gap-3">
                <Link to="/">
                    <img loading="lazy" src={require("../../assets/logo.png").default} alt="logo" className="logo" />
                </Link>

                <p className="text-white">We are here to answer any questions and provide further information. Feel free to reach out to us here:</p>

                <p className="text-white fw-bold">Email: contact@parachaingroup.com<br/> Phone: +40767 572 200</p>
            </div>

            <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                <h1 className="text-white fw-bold">Home</h1>
                <Link to="about" className="text-white">About us</Link>
                <Link to="investment" className="text-white">Investment Focus</Link>
                <Link to="philosophy" className="text-white">Our Philosophy</Link>
            </div>

            <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                <h1 className="text-white fw-bold">Follow Us</h1>
                
                <div className="d-flex flex-row justify-content-start gap-5">
                    <a href="">
                        <img loading="lazy" src={require("../../assets/facebook.svg").default} alt="Facebook. Parachain." />
                    </a>
                    <a href="">
                        <img loading="lazy" src={require("../../assets/linkedin.svg").default} alt="LinkedIn. Parachain." />
                    </a>
                    <a href="">
                        <img loading="lazy" src={require("../../assets/twitter.svg").default} alt="Twitter. Parachain." />
                    </a>
                </div>
            </div>


            <div className="d-flex flex-column justify-content-start align-items-start">
            </div>
        </footer>
    );
}

export default Footer;