import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import NavMenu from "./NavMenu";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scroll, setScroll] = useState(0);
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY);
        });
    }, []);

    const isMobile = width <= 768;

    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = (e) => {
        e && e.preventDefault();
        setShowMenu(!showMenu);
    };

    return (
        <>
            <NavMenu show={showMenu} onClose={() => handleMenu(false)} />

            <header className={scroll > 100 ? "scrolled" : "scrolled--not"}>
                <Link to="/">
                    <img loading="lazy" src={require("../../assets/logo.png").default} alt="Our logo. Parachain." className="logo" />
                </Link>

                {
                    isMobile ?
                    <div className="user-select-none">
                        <img className="menu__option" src={require("../../assets/menu.png").default} alt="Open the menu." onClick={handleMenu} />
                    </div>
                    :
                    <div className="d-flex flex-row justify-content-center">
                        <Link to="header" className="option">Home</Link>
                        <Link to="about" className="option">About us</Link>
                        <Link to="investment" className="option">Investment Focus</Link>
                        <Link to="philosophy" className="option">Our Philosophy</Link>
                    </div>
                }
            </header>
        </>
    );
}

export default Header;